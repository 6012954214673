import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DEFAULT_UUID, IS_CONSTANT_SELECTION, OBJECT, VALIDATION_FORM_TYPE, VALIDATION_LOGICAL_OPERATORS, componentTypeMap } from 'src/app/constant/constant';
import { cloneArray } from 'src/app/constant/globalFunction';
import { LOGMAPPING_DELETE_CONFIRMATION, LOGMAPPING_STATUS_CONFIRMATION, VALIDATION_DELETE_CONFIRMATION, VALIDATION_STATUS_CONFIRMATION } from 'src/app/constant/responseMessage';
import { FieldService } from 'src/app/services/field.service';
import { FormService } from 'src/app/services/form.service';
import { ValidationRuleService } from 'src/app/services/validation-rule.service';
import * as loadashJson from 'lodash';

@Component({
  selector: 'validation-rule-condition',
  templateUrl: './validation-rule-condition.component.html',
  styleUrls: ['./validation-rule-condition.component.css']
})
export class ValidationLogmappingDirectiveComponent implements OnInit{
  
  //Fetched From Validation-Formula Component
  @Input() formType:any;
  @Input() fieldName:any;
  @Input() dataTypeId:any;
  @Input() fetchedVisitId:any;
  @Input() fetchedFormId:any;
  @Input() fetchedFieldId:any;
  @Input() fetchedDataTypeId:any;

  //Fetched From Validation Rule Component
  @Input() fetchValidationData:any
  @Input() formRuleValidation!:FormGroup
  @Input() ruleLength:any
  @Input() tabName:any
  @Input() visitListMainData:any
  @Input() visitListData:any
  @Input() logVisitListMainData:any
  @Input() logVisitListData:any
  
  validationAndLogMapping!:FormGroup;
  isLocked:any
  roles:any
  validateDataFlag:boolean = true
  validationFormTypeData:any = VALIDATION_FORM_TYPE
  saveUpdateFlag:any
  fieldsData:any
  isConstantSelectionBoxData:any = IS_CONSTANT_SELECTION
  logicalOperatorSelectionBoxData:any = VALIDATION_LOGICAL_OPERATORS
  emptyJson:any = {}

  hours12: any[] = []
  hours24: any[] = []
  minutes: any[] = []
  seconds: any[] = []
  midDays: any[] = ["AM", "PM"]

  constructor(private toastr:ToastrService, private validationAPI:ValidationRuleService, private formAPI:FormService, private fieldAPI:FieldService){
  }
  
  ngOnInit(): void {
    this.validationAndLogMapping = this.formRuleValidation
    this.timeInit()
    this.initializeConditionData()
  }

  // Add Condition FormGroup
  addCondition(i:any):FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      visitId:new FormControl(),
      formId:new FormControl(),
      fieldId:new FormControl(),
      multiRowId:new FormControl(),
      optionId:new FormControl(),
      operator:new FormControl(),
      isConstant:new FormControl(),
      dataTypeId:new FormControl(),
      multiRowDataTypeId:new FormControl(),
      validationFormType:new FormControl(this.formType),
      fieldValue:new FormControl(),
      jsonFieldValue:new FormControl({}),
      tableValidationType:new FormControl(1),
      loperator:new FormControl(""),
      eqOrderId:new FormControl(i),
      orderId:new FormControl(i),
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect:new FormControl(),
      uiOperatorSelect:new FormControl(),
      uiOptionSelect:new FormControl(),
      uiFormattingKey:new FormControl(),
      uiHour:new FormControl(),
      uiMinute:new FormControl(),
      uiSecond:new FormControl(),
      uiMidDay:new FormControl(),
    })
  }

  //Add Destination FormGroup if isConstant is NO
  addDestinationKeys():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      visitId:new FormControl(),
      formId:new FormControl(),
      fieldId:new FormControl(),
      multiRowId:new FormControl(),
      dataTypeId:new FormControl(),
      multiRowDataTypeId:new FormControl(),
      validationFormType:new FormControl(),
      loperator:new FormControl(),
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect:new FormControl(),
      uiOptionSelect:new FormControl(),
      uiFormattingKey:new FormControl()
    })
  }

  //Getting FormArray Rule Key
  get getValidationOrLogMappingRuleKey(){
    return (this.validationAndLogMapping.get("rule") as FormArray)
  }

  //Getting Selected Rule Key Value
  getSelectedRuleKeyValue(keyName:any,i:any){
    return ((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.value
  }

  //Setting Selected Rule Key Value
  setSelectedRuleKeyValue(keyName:any,i:any,value:any){
    return((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  getConditionOrImpactArrayRule(key:any,i:any){
    return (((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray)
  }

  getConditionOrImpactArrayKey(key:any,i:any,j:any){
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray).get(j.toString()) as FormGroup)
  }

  getSelectedKeyValueForConditionAndImpact(mainKey:any,keyName:any,i:any,j:any){
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName)
  }

  setSelectedKeyValueForConditionAndImpact(mainKey:any,keyName:any,i:any,j:any,value:any){    
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  getSelectedKeyValueForDestinationConditionAndImpact(mainKey:any,keyName:any,innerKey:any,i:any,j:any){
    return (((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName) as FormGroup).get(innerKey)
  }

  setSelectedKeyValueForDestinationConditionAndImpact(mainKey:any,keyName:any,innerKey:any,i:any,j:any,value:any){    
    return (((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName) as FormGroup).get(innerKey)?.setValue(value)
  }
  
  //Initialize the Condition Data in OnInit() Method
  initializeConditionData(){
    // this.setSelectedKeyValueForConditionAndImpact('condition', 'validationFormType', this.ruleLength, 0, this.formType);
    // this.getVisitFormData()
    this.initialize1stConditionData(this.formType)
  }
  
  testFields:any
  initialize1stConditionData(formType:any){
    let forms:any
    if(formType > 0){
      this.setSelectedKeyValueForConditionAndImpact('condition', 'visitId', this.ruleLength, 0, DEFAULT_UUID);
      forms = this.logVisitListMainData['visit'][DEFAULT_UUID].forms
    }
    if(this.getSelectedRuleKeyValue("id",this.ruleLength) != null){
      //TODO: remove if not needed
      if(this.fetchValidationData){
        let previousFormId:any = 0
        const conditionDataLength = this.fetchValidationData[this.ruleLength].condition.length
        if(conditionDataLength > 1 && this.getConditionOrImpactArrayRule("condition",this.ruleLength).length != conditionDataLength){
          for (let conditionLength = 1; conditionLength < conditionDataLength; conditionLength++) {
            this.getConditionOrImpactArrayRule("condition",this.ruleLength).push(this.addCondition(conditionLength))
          }
        }
        for (let validationConditionIndex = 0; validationConditionIndex < this.fetchValidationData[this.ruleLength].condition.length; validationConditionIndex++) {
          this.getConditionOrImpactArrayKey("condition",this.ruleLength,validationConditionIndex).reset()
          this.getConditionOrImpactArrayKey("condition",this.ruleLength,validationConditionIndex).patchValue(this.fetchValidationData[this.ruleLength].condition[validationConditionIndex])
          this.setSelectedKeyValueForConditionAndImpact('condition', 'id', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].id);
          this.setSelectedKeyValueForConditionAndImpact('condition', 'validationFormType', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].visitId == DEFAULT_UUID ? this.validationFormTypeData[1].id : this.validationFormTypeData[0].id);
          this.setSelectedKeyValueForConditionAndImpact('condition', 'visitId', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].visitId);
          this.setSelectedKeyValueForConditionAndImpact('condition', 'orderId', this.ruleLength, validationConditionIndex, validationConditionIndex);
          this.setSelectedKeyValueForConditionAndImpact('condition', 'eqOrderId', this.ruleLength, validationConditionIndex, validationConditionIndex);
          forms = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].visitId == DEFAULT_UUID ? this.logVisitListMainData['visit'][this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].visitId].forms : this.visitListMainData['visit'][this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].visitId].forms
          let extractedForms = this.extractForms(forms)
          this.setSelectedKeyValueForConditionAndImpact("condition","uiFormSelect",this.ruleLength,validationConditionIndex,extractedForms);
          this.setSelectedKeyValueForConditionAndImpact('condition', 'formId', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].formId);
          //TODO: remove if needed
          if(this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].formId){
            //TODO: for reducing the api calls of fieldsListAPI
            this.fieldAPI.getFieldsByForm(this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].formId).subscribe(res => {
              this.testFields = cloneArray(res.responseObject)
              this.initializeConditionDataForFieldsAPI(validationConditionIndex,this.testFields)
              previousFormId = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].formId
            });
          }else{
            console.log("FORMID NOT FOUND");
            
          }
          
        }
      }
      
    }else{
      if(formType == 0){
        this.setSelectedKeyValueForConditionAndImpact('condition', 'visitId', this.ruleLength, 0, this.fetchedVisitId);
        forms = this.visitListMainData['visit'][this.fetchedVisitId].forms
      }else{
        forms = this.logVisitListMainData['visit'][DEFAULT_UUID].forms

      }
      let extractedForms = this.extractForms(forms)
      this.setSelectedKeyValueForConditionAndImpact("condition","uiFormSelect",this.ruleLength,0,extractedForms);
      this.setSelectedKeyValueForConditionAndImpact('condition', 'formId', this.ruleLength, 0, this.fetchedFormId);
      if(this.fetchedFormId){
        this.fieldAPI.getFieldsByForm(this.fetchedFormId).subscribe(res => {
          console.log("API DATA :: ",res);
          
          this.setSelectedKeyValueForConditionAndImpact('condition', 'uiFieldSelect', this.ruleLength, 0, cloneArray(res.responseObject));
          this.setSelectedKeyValueForConditionAndImpact('condition', 'fieldId', this.ruleLength, 0, this.fetchedFieldId);
          this.onFieldSelect({value:this.fetchedFieldId},"condition",this.ruleLength,0,false)
          // this.initializeConditionOperators(selectedFieldsDataTypeId,"condition",0,0)
        });
      }
    }
    console.log("FORM :: ",this.validationAndLogMapping);
    

  }

  initializeConditionDataForFieldsAPI(validationConditionIndex:any,fieldsData:any){
    const fetchValidationDataTypeId = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dataTypeId
    const fetchValidationDestinationData = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest
    
    this.setSelectedKeyValueForConditionAndImpact('condition', 'uiFieldSelect', this.ruleLength, validationConditionIndex, fieldsData);
    this.setSelectedKeyValueForConditionAndImpact('condition', 'fieldId', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].fieldId);
    
    if(fetchValidationDestinationData){
      this.getConditionOrImpactArrayKey("condition",this.ruleLength,validationConditionIndex).addControl("dest",this.addDestinationKeys())
      const destValidationFormType = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['visitId'] == DEFAULT_UUID ? this.validationFormTypeData[1].id : this.validationFormTypeData[0].id
      this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","validationFormType",this.ruleLength,validationConditionIndex,destValidationFormType)
      this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","visitId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['visitId'])
      const destFormsData = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['visitId'] == DEFAULT_UUID ? this.logVisitListMainData['visit'][this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['visitId']].forms : this.visitListMainData['visit'][this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['visitId']].forms
      const destFormId = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['formId']
      const destExtractedForms = this.extractForms(destFormsData)
      this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","uiFormSelect",this.ruleLength,validationConditionIndex,destExtractedForms)
      this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","formId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['formId'])
      this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","loperator",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['loperator'])
      //TODO: check destFormId and formId is same -> if same then no need for api call, if Not then call api
      if(this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].formId == destFormId){
        this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","uiFieldSelect",this.ruleLength,validationConditionIndex,this.testFields)
        this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","fieldId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['fieldId'])
        this.onFieldSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['fieldId']},"condition",this.ruleLength,validationConditionIndex,true)
      }else{
        this.fieldAPI.getFieldsByForm(destFormId).subscribe(res => {
          this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","uiFieldSelect",this.ruleLength,validationConditionIndex,cloneArray(res.responseObject))
          this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","fieldId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['fieldId'])
          this.onFieldSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['fieldId']},"condition",this.ruleLength,validationConditionIndex,true)
        });
      }
      
    }else{
      console.log("CALL");
      this.onFieldSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].fieldId},"condition",this.ruleLength,validationConditionIndex,false)
    }
    const fetchValidationMultiRowDataTypeId = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowDataTypeId
    if(fetchValidationDataTypeId == componentTypeMap[16].id){
      console.log("fetchValidationDestinationData :: ",fetchValidationDestinationData);
      
      if(fetchValidationDestinationData){
        this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","multiRowId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['multiRowId'])
        if(this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['multiRowId']){
          this.onMultiRowColumnSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['multiRowId']},"condition",this.ruleLength,validationConditionIndex,true)
        }
      }else{
        this.setSelectedKeyValueForConditionAndImpact('condition', 'multiRowId', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId);
        this.setSelectedKeyValueForConditionAndImpact('condition', 'jsonFieldValue', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].jsonFieldValue);
        this.onMultiRowColumnSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId},"condition",this.ruleLength,validationConditionIndex,false)
        Object.keys(this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].jsonFieldValue).filter((option:any) => {
          this.setSelectedKeyValueForConditionAndImpact('condition', 'optionId', this.ruleLength, validationConditionIndex, Number(option));
          this.setSelectedKeyValueForConditionAndImpact('condition', 'jsonFieldValue', this.ruleLength, validationConditionIndex, Number(option));
        })
      }
    }else if(fetchValidationDataTypeId == componentTypeMap[17].id){
      if(fetchValidationDestinationData){
        this.setSelectedKeyValueForDestinationConditionAndImpact("condition","dest","multiRowId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].dest['multiRowId'])
        this.onTableComponentColumnSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId},"condition",this.ruleLength,validationConditionIndex,true)
      }else{
        this.setSelectedKeyValueForConditionAndImpact('condition', 'multiRowId', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId);
        this.setSelectedKeyValueForConditionAndImpact('condition', 'jsonFieldValue', this.ruleLength, validationConditionIndex, this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].jsonFieldValue != undefined ? Object.keys(this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].jsonFieldValue)[0] : null);
        this.onTableComponentColumnSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId},"condition",this.ruleLength,validationConditionIndex,false)
        

      }
    }
    if(!fetchValidationDestinationData){
      this.setSelectedKeyValueForConditionAndImpact("condition","isConstant",this.ruleLength,validationConditionIndex,'1');
      if([componentTypeMap[5].id,componentTypeMap[6].id,componentTypeMap[7].id].includes(fetchValidationDataTypeId)){
        this.setSelectedKeyValueForConditionAndImpact("condition","jsonFieldValue",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].optionId);
        this.setSelectedKeyValueForConditionAndImpact("condition","optionId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].optionId);
      }else if(fetchValidationDataTypeId == componentTypeMap[11].id){
        this.setSelectedKeyValueForConditionAndImpact("condition","jsonFieldValue",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].jsonFieldValue);
      }else if([componentTypeMap[12].id,componentTypeMap[18].id].includes(fetchValidationDataTypeId) || [componentTypeMap[12].id,componentTypeMap[18].id].includes(fetchValidationMultiRowDataTypeId)){
        let timeData:any = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].jsonFieldValue
        if(timeData){
          if(timeData['hour']){
            this.setSelectedKeyValueForConditionAndImpact("condition","uiHour",this.ruleLength,validationConditionIndex,timeData['hour']);
          }
          if(timeData['minute']){
            this.setSelectedKeyValueForConditionAndImpact("condition","uiMinute",this.ruleLength,validationConditionIndex,timeData['minute']);
          }
          if(timeData['second']){
            this.setSelectedKeyValueForConditionAndImpact("condition","uiSecond",this.ruleLength,validationConditionIndex,timeData['second']);
          }
          if(timeData['midDay']){
            this.setSelectedKeyValueForConditionAndImpact("condition","uiMidDay",this.ruleLength,validationConditionIndex,timeData['midDay']);
          }
        }
        this.setSelectedKeyValueForConditionAndImpact("condition","jsonFieldValue",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].jsonFieldValue);
      }
      this.setSelectedKeyValueForConditionAndImpact("condition","fieldValue",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].fieldValue);
      this.onIsConstantSelect({value:'1'},"condition",this.ruleLength,validationConditionIndex,true)
    }else{
     //when dest field have data as well as main field data then this block will called
     this.onFieldSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].fieldId},"condition",this.ruleLength,validationConditionIndex,false)
     this.setSelectedKeyValueForConditionAndImpact("condition","isConstant",this.ruleLength,validationConditionIndex,'2');
     this.onIsConstantSelect({value:'2'},"condition",this.ruleLength,validationConditionIndex,true)
     if(fetchValidationDataTypeId == componentTypeMap[16].id || fetchValidationDataTypeId == componentTypeMap[17].id){
       this.setSelectedKeyValueForConditionAndImpact("condition","multiRowId",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId)
       const selectedFieldData:any = this.getSelectedKeyValueForConditionAndImpact("condition","uiColumnSelect",this.ruleLength,validationConditionIndex)?.value
       const multiRowDataTypeId:any = selectedFieldData.find((field:any) => field.id == this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId).dataTypeId
       this.setSelectedKeyValueForConditionAndImpact("condition","multiRowDataTypeId",this.ruleLength,validationConditionIndex,multiRowDataTypeId);
       if([6,7,8].includes(multiRowDataTypeId)){
         const multiRowColumnOptionData:any = selectedFieldData.find((field:any) => field.id == this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].multiRowId).options
         this.setSelectedKeyValueForConditionAndImpact("condition","uiOptionSelect",this.ruleLength,validationConditionIndex,multiRowColumnOptionData);
       }
       this.initializeConditionOperators(multiRowDataTypeId,"condition",this.ruleLength,validationConditionIndex)
       this.setSelectedKeyValueForConditionAndImpact("condition","operator",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].operator);
       this.setSelectedKeyValueForConditionAndImpact("condition","loperator",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].loperator);
     }else{
       this.setSelectedKeyValueForConditionAndImpact("condition","operator",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].operator);
       this.initializeConditionOperators(fetchValidationDataTypeId,"condition",this.ruleLength,validationConditionIndex)
       this.setSelectedKeyValueForConditionAndImpact("condition","loperator",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].loperator);
     }
    }

    const fetchValidationLOperatorData = this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].loperator

    if(fetchValidationLOperatorData){
      this.setSelectedKeyValueForConditionAndImpact("condition","loperator",this.ruleLength,validationConditionIndex,this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].loperator);
      this.onLogicalOperatorSelect({value:this.fetchValidationData[this.ruleLength].condition[validationConditionIndex].loperator},"condition",this.ruleLength,validationConditionIndex)
    }
  }
  
  //Getting Visit Data from API
  getVisitFormData(){
    this.formAPI.getVisitFormData('').subscribe(res => {
      this.visitListMainData = res.responseObject
      const visits = res.responseObject['visit']
      this.visitListData = cloneArray(this.extractVisits(visits))
    })
    this.getLogFormData()
  }

  //Getting Log Forms from API
  getLogFormData(){
    this.formAPI.getLogFormAPIData().subscribe(res => {
      this.logVisitListMainData = res.responseObject
      const visits = res.responseObject['visit']
      this.logVisitListData = cloneArray(this.extractVisits(visits))
      const forms = this.logVisitListMainData['visit'][DEFAULT_UUID].forms
      this.formType == 1 ? this.setSelectedKeyValueForConditionAndImpact("condition","uiFormSelect",0,0,this.extractForms(forms)) : null
      this.initialize1stConditionData(this.formType)
    })
    // this.getLogFormFieldData()
  }

  //Get Fields from formId API
  getFieldData(formId:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    this.fieldAPI.getFieldsByForm(formId).subscribe(res => {
      this.fieldsData = cloneArray(res.responseObject)
      if(isDestination){
        this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey, 'dest', 'uiFieldSelect', ruleIndex, conditionOrLogMappingIndex, cloneArray(this.fieldsData));
      }else{
        this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey, 'uiFieldSelect', ruleIndex, conditionOrLogMappingIndex, cloneArray(this.fieldsData));
      }
    });
  }

  // Extracting Visit Data for setting it to selectionBox
  extractVisits(visits: any): any[] {
    let visitData:any[] = []
    Object.keys(visits).map((key) => {
      visitData.push({ id: key, name: visits[key].name })
    })
    return visitData
  }

  //Extracting Forms Data for setting it to selectionBox
  extractForms(visits: any[]) {
    let formsArray:any[] = []
    Object.keys(visits).map((key:any) => {
      formsArray.push({formId: visits[key].formId, formName: visits[key].formName, formType:visits[key].formType})
    })
    return formsArray
  }

  //OnChange the ValidationFormType inside the condition
  onValidationFormTypeSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    const selectedValidationFormType = event.value
    this.resetFormControls(['uiFormSelect','formId','fieldId','dataTypeId','multiRowId','multiRowDataTypeId','uiFieldSelect','optionId','uiOptionSelect',"operator","isConstant","fieldValue","jsonFieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    if(selectedValidationFormType > 0){
      isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","validationFormType",ruleIndex,conditionOrLogMappingIndex,1) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"validationFormType",ruleIndex,conditionOrLogMappingIndex,1)
      isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","visitId",ruleIndex,conditionOrLogMappingIndex,DEFAULT_UUID) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"visitId",ruleIndex,conditionOrLogMappingIndex,DEFAULT_UUID)
      const forms = this.logVisitListMainData['visit'][DEFAULT_UUID].forms
      isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiFormSelect",ruleIndex,conditionOrLogMappingIndex,this.extractForms(forms) ) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiFormSelect",ruleIndex,conditionOrLogMappingIndex,this.extractForms(forms) )
    }
  }

  //OnChange Method for visit selectionBox
  onVisitSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    this.resetFormControls(['uiFormSelect','formId','fieldId','dataTypeId','multiRowId','multiRowDataTypeId','uiFieldSelect','optionId','uiOptionSelect',"operator","isConstant","fieldValue","jsonFieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    if(event.value != 'null'){
      const forms = this.visitListMainData['visit'][event.value.toString()].forms
      if(isDestination){
        // this.resetFormControls(['uiFormSelect','formId'],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
        this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey, 'dest', 'uiFormSelect', ruleIndex, conditionOrLogMappingIndex, this.extractForms(forms));
      }else{
        this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey, 'uiFormSelect', ruleIndex, conditionOrLogMappingIndex, this.extractForms(forms));
      }
    }
  }

  //OnChange Method for form selectionBox
  onFormSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    if(isDestination){
      this.resetFormControls(['fieldId','dataTypeId','multiRowId','multiRowDataTypeId','optionId','uiOptionSelect',"isConstant","fieldValue","jsonFieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }else{
      this.resetFormControls(['fieldId','dataTypeId','multiRowId','multiRowDataTypeId','optionId','uiOptionSelect',"operator","isConstant","fieldValue","jsonFieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }
    if(event.value != 'null'){
      const formId = event.value
      this.getFieldData(formId,conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }
  }

  //OnChange Method for field selectionBox
  onFieldSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    if(!isDestination){
      this.resetFormControls(['dataTypeId','multiRowId','multiRowDataTypeId','optionId','uiOptionSelect',"isConstant","fieldValue","jsonFieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }
    if(event.value != 'null'){
      const fieldId = event.value
      const selectedFieldData:any = isDestination ? this.getSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,'dest',"uiFieldSelect",ruleIndex,conditionOrLogMappingIndex)?.value : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiFieldSelect",ruleIndex,conditionOrLogMappingIndex)?.value
      const dataTypeId:any = selectedFieldData.find((field:any) => field.id == fieldId).dataTypeId
      
      if(!isDestination){
        this.initializeConditionOperators(dataTypeId,conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
      }
      if([componentTypeMap[12].id,componentTypeMap[18].id].includes(dataTypeId)){
        const formatting:any = selectedFieldData.find((field:any) => field.id == fieldId).formatting
        isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiFormattingKey",ruleIndex,conditionOrLogMappingIndex,formatting) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiFormattingKey",ruleIndex,conditionOrLogMappingIndex,formatting);
        console.log("DAT :: ",dataTypeId,formatting,this.validationAndLogMapping);
      }
      //TODO: create function for isDesitnation condition
      isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","dataTypeId",ruleIndex,conditionOrLogMappingIndex,dataTypeId) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"dataTypeId",ruleIndex,conditionOrLogMappingIndex,dataTypeId);
      if(dataTypeId == componentTypeMap[16].id){
        console.log("SELECTED :: ",selectedFieldData,fieldId,isDestination);
        const multiRowColumnData:any = selectedFieldData.find((field:any) => field.id == fieldId).columnData
        isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiColumnSelect",ruleIndex,conditionOrLogMappingIndex,multiRowColumnData) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiColumnSelect",ruleIndex,conditionOrLogMappingIndex,multiRowColumnData);
      }else if(dataTypeId == componentTypeMap[17].id){
        let cellInfoData:any[] = []
        const tableComponentData:any = selectedFieldData.find((field:any) => field.id == fieldId).cellInfo
        if(tableComponentData){
          
          for (let index = 1; index < tableComponentData[0].length; index++) {
            tableComponentData[0][index][0].tableColumnIndex = index
            tableComponentData[0][index][0].colHeading = tableComponentData[0][index][0].label
            cellInfoData.push(tableComponentData[0][index][0])
          }
        }
        isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiColumnSelect",ruleIndex,conditionOrLogMappingIndex,cellInfoData) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiColumnSelect",ruleIndex,conditionOrLogMappingIndex,cellInfoData);
      }
    }
  }

  //OnChange Method for Multi-Row Column Data SelectionBox
  onMultiRowColumnSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    if(isDestination){
      this.resetFormControls(["loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }else{
      this.resetFormControls(['dest','multiRowDataTypeId','uiOptionSelect',"isConstant","fieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }
    if(event.value != 'null'){
      const fieldId = event.value
      const selectedFieldData:any = isDestination ? this.getSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiColumnSelect",ruleIndex,conditionOrLogMappingIndex)?.value : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiColumnSelect",ruleIndex,conditionOrLogMappingIndex)?.value
      const multiRowDataTypeId:any = selectedFieldData.find((field:any) => field.id == fieldId).dataTypeId
      isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","multiRowDataTypeId",ruleIndex,conditionOrLogMappingIndex,multiRowDataTypeId) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"multiRowDataTypeId",ruleIndex,conditionOrLogMappingIndex,multiRowDataTypeId);
      const dataTypeId:any = selectedFieldData.find((field:any) => field.id == fieldId).dataTypeId
      if([componentTypeMap[12].id,componentTypeMap[18].id].includes(dataTypeId)){
        const formatting:any = selectedFieldData.find((field:any) => field.id == fieldId).formatting
        isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiFormattingKey",ruleIndex,conditionOrLogMappingIndex,formatting) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiFormattingKey",ruleIndex,conditionOrLogMappingIndex,formatting);
        console.log("DAT :: ",selectedFieldData,dataTypeId,formatting,this.validationAndLogMapping);
      }
      if([componentTypeMap[5].id,componentTypeMap[6].id,componentTypeMap[7].id].includes(multiRowDataTypeId)){
        const multiRowColumnOptionData:any = selectedFieldData.find((field:any) => field.id == fieldId).options
        isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiOptionSelect",ruleIndex,conditionOrLogMappingIndex,multiRowColumnOptionData) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiOptionSelect",ruleIndex,conditionOrLogMappingIndex,multiRowColumnOptionData);
      }
      if(conditionOrLogMappingKey == "condition" && !isDestination){
        this.initializeConditionOperators(multiRowDataTypeId,conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
      }
    }
  }

  //OnChange Method for Table Component Data SelectionBox
  onTableComponentColumnSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    if(isDestination){
      this.resetFormControls(["loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }else{
      this.resetFormControls(['dest','multiRowDataTypeId','uiOptionSelect',"isConstant","fieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,isDestination)
    }
    if(event.value != 'null'){
      const tableColumnIndexData = event.value
      const fieldId = isDestination ? this.getSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","fieldId",ruleIndex,conditionOrLogMappingIndex)?.value : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"fieldId",ruleIndex,conditionOrLogMappingIndex)?.value
      const fieldData:any = isDestination ? this.getSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiFieldSelect",ruleIndex,conditionOrLogMappingIndex)?.value : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiFieldSelect",ruleIndex,conditionOrLogMappingIndex)?.value
      const selectedFieldData = fieldData.find((field:any) => field.id == fieldId)
      const selectedColumnFieldData:any = isDestination ? this.getSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","uiColumnSelect",ruleIndex,conditionOrLogMappingIndex)?.value : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiColumnSelect",ruleIndex,conditionOrLogMappingIndex)?.value
      const tableComponentData:any = selectedColumnFieldData.find((field:any) => field.tableColumnIndex == tableColumnIndexData)
      let dataTypeId:any
      let tableComponentFinalData:any
      if(selectedFieldData.cellInfo){
        for (let i = 1; i < selectedFieldData.cellInfo[0].length; i++) {
          if(i == tableComponentData.tableColumnIndex){
            dataTypeId = selectedFieldData.cellInfo[1][i][0].dataTypeId
            tableComponentFinalData = selectedFieldData.cellInfo[1][i][0]
          }
        }
      }
      
      isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest","multiRowDataTypeId",ruleIndex,conditionOrLogMappingIndex,Number(dataTypeId)) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"multiRowDataTypeId",ruleIndex,conditionOrLogMappingIndex,Number(dataTypeId));
  
      if([6,7,8].includes(dataTypeId)){
        Object.keys(this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"jsonFieldValue",ruleIndex,conditionOrLogMappingIndex)?.value).filter((option:any) => {
          console.log("OPTION :: ",option);
          
          this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey, "optionId", ruleIndex, conditionOrLogMappingIndex, option);
        })
        isDestination ? this.setSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest",'uiOptionSelect',ruleIndex,conditionOrLogMappingIndex,tableComponentFinalData.options) : this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,'uiOptionSelect',ruleIndex,conditionOrLogMappingIndex,tableComponentFinalData.options);
        console.log(this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"jsonFieldValue",ruleIndex,conditionOrLogMappingIndex)?.value);
        
      }
      if(conditionOrLogMappingKey == "condition" && !isDestination){
        this.initializeConditionOperators(dataTypeId,conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
      }
      console.log("VALIDATION :: ",this.validationAndLogMapping);
      
    }
  }

  //Adding operators in selectionBox
  initializeConditionOperators(dataTypeId:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionIndex:any){
    let operator:any[] = [] 

    if([componentTypeMap[0].id,componentTypeMap[1].id,componentTypeMap[3].id,
      componentTypeMap[4].id,componentTypeMap[5].id,componentTypeMap[6].id,componentTypeMap[7].id].includes(dataTypeId)){
      operator.push(
        {id:5,name:"="},
        {id:6,name:"!="}
      )
    }else if([componentTypeMap[2].id,componentTypeMap[11].id,componentTypeMap[12].id,componentTypeMap[18].id].includes(dataTypeId)){
      operator.push(
        {id:1,name:">"},
        {id:2,name:"<"},
        {id:3,name:">="},
        {id:4,name:"<="},
        {id:5,name:"="},
        {id:6,name:"!="}
      )
    }
    this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiOperatorSelect",ruleIndex,conditionIndex,operator)
    operator = []
    
  }

  //OnChange Method for Operator selectionBox
  onOperatorSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    // this.resetFormControls(['isConstant'],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
    this.resetFormControls(["isConstant","fieldValue","loperator"],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,false)
    if(event.value != 'null'){
      const dataTypeId:any = ![17,18].includes(this.getSelectedKeyValueForConditionAndImpact( conditionOrLogMappingKey, 'dataTypeId', ruleIndex, conditionOrLogMappingIndex)?.value) ? this.getSelectedKeyValueForConditionAndImpact( conditionOrLogMappingKey, 'dataTypeId', ruleIndex, conditionOrLogMappingIndex)?.value : this.getSelectedKeyValueForConditionAndImpact( conditionOrLogMappingKey, 'multiRowDataTypeId', ruleIndex, conditionOrLogMappingIndex)?.value;
      if([6,7,8].includes(dataTypeId)){
        this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey, 'isConstant', ruleIndex, conditionOrLogMappingIndex, "1");
        this.onIsConstantSelect({value:'1'},conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,false)
      }
    }
    
  }

  //OnChange Method for isConstant selectionBox
  onIsConstantSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isValidationDataFetched:any){
    const isConstantValue = event.value
    if(!isValidationDataFetched){
      this.resetFormControls(['dest','fieldValue','loperator'],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex,false)
    }
    if(isConstantValue != 'null'){
      const dataTypeId:any = this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"dataTypeId",ruleIndex,conditionOrLogMappingIndex)?.value
      if(isConstantValue == this.isConstantSelectionBoxData[0].id){
        let mainFieldData = dataTypeId != componentTypeMap[16].id ? this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"fieldId",ruleIndex,conditionOrLogMappingIndex)?.value : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"multiRowId",ruleIndex,conditionOrLogMappingIndex)?.value
        let mainId = dataTypeId != componentTypeMap[16].id ? "uiFieldSelect" : "uiColumnSelect";
        let options = this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,mainId,ruleIndex,conditionOrLogMappingIndex)?.value.find((cntry: any) => cntry.id == mainFieldData)?.options;
        if(this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiOptionSelect",ruleIndex,conditionOrLogMappingIndex)?.value == null){
          this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiOptionSelect",ruleIndex,conditionOrLogMappingIndex,options);
        }
      }else{
        this.getConditionOrImpactArrayKey(conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex).addControl("dest",this.addDestinationKeys())
      }
    }
  }

  //OnChange Logical Operator selectionBox
  onLogicalOperatorSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    const logicalOperatorData = event.value
    if(logicalOperatorData != 'null'){
      const conditionData = this.getConditionOrImpactArrayRule(conditionOrLogMappingKey,ruleIndex)
      if(( conditionOrLogMappingIndex === null && conditionData.length > 0) || conditionData.length > conditionOrLogMappingIndex + 1 ){
        return
      }else{
        this.getConditionOrImpactArrayRule(conditionOrLogMappingKey,ruleIndex).push(this.addCondition(ruleIndex+1))
        // if(this.getSelectedRuleKeyValue("id",ruleIndex) != null){
        //   this.initialize1stConditionData(this.formType,true)
        // }
      }
    }
  }

  removeCondition(ruleIndex:any,conditionOrLogMappingIndex:any){
    if(this.getConditionOrImpactArrayRule("condition",ruleIndex).length > 1){
      this.getConditionOrImpactArrayRule("condition",ruleIndex).removeAt(conditionOrLogMappingIndex)
      this.getSelectedKeyValueForConditionAndImpact("condition","loperator",ruleIndex,conditionOrLogMappingIndex-1)?.reset();
      ((this.getSelectedKeyValueForConditionAndImpact("condition","dest",ruleIndex,conditionOrLogMappingIndex-1) as FormGroup).get("loperator") as FormControl).reset()
    }
  }

  //reset unwanted formControls
  resetFormControls(controlNameArray:any[],conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any,isDestination:any){
    if(controlNameArray && controlNameArray.length > 0){
      for (let controlName in controlNameArray){
        if(controlNameArray[controlName] == "jsonFieldValue"){
          isDestination ? this.getSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest",controlNameArray[controlName],ruleIndex,conditionOrLogMappingIndex)?.setValue({}) : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,controlNameArray[controlName],ruleIndex,conditionOrLogMappingIndex)?.setValue({})
        }else if (controlNameArray[controlName] == "dest"){
          this.getConditionOrImpactArrayKey(conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex).removeControl('dest')
        }else{
          isDestination ? this.getSelectedKeyValueForDestinationConditionAndImpact(conditionOrLogMappingKey,"dest",controlNameArray[controlName],ruleIndex,conditionOrLogMappingIndex)?.reset() : this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,controlNameArray[controlName],ruleIndex,conditionOrLogMappingIndex)?.reset()

        }
      }
    }
  }

  //For Time
  timeInit() {
    for (let hr = 0; hr < 12; hr++) {
      if(hr.toString().length < 2){
        this.hours12.push(('0' + hr).slice(-2));
      }else{
        this.hours12.push(hr.toString());
      }
    }
    
    for (let hr = 0; hr < 24; hr++) {
      if(hr.toString().length < 2){
        this.hours24.push(('0' + hr).slice(-2));
      }else{
        this.hours24.push(hr.toString());
      }
    }

    for (let min = 0; min < 60; min++) {
      if(min.toString().length < 2){
        this.minutes.push(('0' + min).slice(-2));
      }else{
        this.minutes.push(min.toString());
      }
    }

    for (let sec = 0; sec < 60; sec++) {
      if(sec.toString().length < 2){
        this.seconds.push(('0' + sec).slice(-2));
      }else{
        this.seconds.push(sec.toString());
      }
    }
    
  }

}
