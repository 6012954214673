import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { baseURL , VERSION } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private dataIndex = new BehaviorSubject<any>(null);
  private dataFormId = new BehaviorSubject<any>("");
  currentIndex = this.dataIndex.asObservable();
  currentFormEdit = this.dataFormId.asObservable();

  constructor(private http: HttpClient) { }
  editFormId(formId: any) {
    this.dataFormId.next(formId);
  }

  getUser(id: any): Observable<any> {
    return this.http.get(`${baseURL}users/${VERSION}?id=${id}`);
  }

  postIndex(currentIndex: any) {
    this.dataIndex.next(currentIndex);
  }

  postForm(data: any): Observable<any> {
    return this.http.post(`${baseURL}forms/${VERSION}`, data);
  }

  getForm(): Observable<any> {
    return this.http.get(`${baseURL}forms/${VERSION}/list`);
  }

  getFormById(id: any): Observable<any> {
    return this.http.get(`${baseURL}forms/${VERSION}?id=${id}`);
  }

  updateForm(data: any): Observable<any> {
    return this.http.put(`${baseURL}forms/${VERSION}`, data);
  }

  deleteForm(id: string): Observable<any> {
    return this.http.delete(`${baseURL}forms/${VERSION}?id=${id}`);
  }

  hardDeleteForm(id: string): Observable<any> {
    return this.http.delete(`${baseURL}forms/${VERSION}/hardDelete?formIdList=${id}`);
  }

  getFieldValidationData(formId:any,visitId:any,subjectId?:any):Observable<any>{
    let url:string = `${baseURL}forms/${VERSION}/fieldValidation?formId=${formId}&visitId=${visitId}`
    if(subjectId){
      url = url.concat(`&subjectId=${subjectId}`)
    }
    return this.http.get(url);
  }

  getVisitFormData(subjectIds:any):Observable<any>{
    return this.http.get(`${baseURL}common/${VERSION}/visitForm?subjectIds=${subjectIds}`);
  }

  getLogFormAPIData():Observable<any>{
    return this.http.get(`${baseURL}common/${VERSION}/logform`);
  }

  getLogFormsData():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/logForms`);
  }
  
  getFormData(id:any):Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/formData?id=${id}`);
  }

  setOrderId(data:any):Observable<any>{
    return this.http.put(`${baseURL}forms/${VERSION}/orderId`,data);
  }

  getStudyCompletionForm():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/studyCompletion`);
  }

  getLogFormField():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/logFormField`);
  }

  getFormType(formId:any):Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/formType?formId=${formId}`);
  }

  getAllDataStudyWise():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/idName`);
  }

  exportForms(data:any):Observable<any>{
    return this.http.post(`${baseURL}export/form`,data);
  }
}